 //! IMPORTANT: Now We dont Need to pass User ID (user.id) as in Backend It will take loggedIn UserId Automatically from req.user and Then Add to Api Call. Hence From Frontend we just need to hit on paticular Api Path without userId

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addToCart,
  fetchCartItemsByUserId,
  updateCart,
  deleteFromCart,
  resetCart,
} from "./CartApi";

const initialState = {
  items: [],
  status: "idle",
  cartLoaded:false,
};

// Add to Cart
export const addToCartAsync = createAsyncThunk(
  "cart/addToCart",
  async (item) => {
    const response = await addToCart(item);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

//Get Cart Details By user id
//LoggedIn UserId Automatically get in Backend 
export const fetchCartItemsByUserIdAsync = createAsyncThunk(
  "cart/fetchCartItemsByUserId",
  async () => {
    const response = await fetchCartItemsByUserId();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

//To update Cart Details
export const updateCartAsync = createAsyncThunk(
  "cart/updateCart",
  async (update) => {
    const response = await updateCart(update);

    return response.data;
  }
);

//To Delete Items from Cart
export const deleteFromCartAsync = createAsyncThunk(
  "cart/deleteFromCart",
  async (itemId) => {
    const response = await deleteFromCart(itemId);
    return response.data;
  }
);
// Reset Cart After order is placed
// userId from backend
export const resetCartAsync = createAsyncThunk(
  "cart/resetCart",
  async () => {
    const response = await resetCart();
    return response.data;
  }
);






export const CartSlice = createSlice({
  name: "cart",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(addToCartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addToCartAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.items.push(action.payload);
      })
      .addCase(fetchCartItemsByUserIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCartItemsByUserIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.items = action.payload;
        state.cartLoaded = true;
      })
      .addCase(fetchCartItemsByUserIdAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.items =[];
        state.cartLoaded = true;
      })
      .addCase(updateCartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCartAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // here we find items wle array me use item ko jis ke id action.payload.id ke equal ho
        // and then replace that item in items Array by action.payload
        const index = state.items.findIndex(
          (item) => item.id === action.payload.id
        );
        state.items[index] = action.payload;
      })
      // to Remove Item From Cart
      .addCase(deleteFromCartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteFromCartAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // to find Item
        const index = state.items.findIndex(
          (item) => item.id === action.payload.id
        );
        // to delete item
        state.items.splice(index, 1);
      })
      .addCase(resetCartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resetCartAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.items = [];
      })
  },
});

export const { increment } = CartSlice.actions;

// Selectors
// to get cart details
export const selectCartItems = (state) => state.cart.items;
export const selectCartStatus = (state) => state.cart.status;
export const selectCartLoaded = (state) => state.cart.cartLoaded;

export default CartSlice.reducer;

// Fetch Items By user id is dispatche at top level APp Level As with User Login it will get the no of products in Cart and update the  cart Badge
