import React from 'react'
import ResestPassword from '../features/Auth/componenets/ResestPassword'

const ResetPasswordPage = () => {
  return (
    <div>
          <ResestPassword></ResestPassword>
    </div>
  )
}

export default ResetPasswordPage;
