import React from "react";
import NavBar from "../features/Navbar/NavBar";
const NavBarPage = () => {
  return (
    <div>
      <NavBar></NavBar>
    </div>
  );
};

export default NavBarPage;
