import React from 'react'
import AdminProductEditForm from '../features/admin/components/AdminProductEditForm'
const AdminProductEditFormPage = () => {
  return (
    <div>
          <AdminProductEditForm></AdminProductEditForm>
    </div>
  )
}

export default AdminProductEditFormPage;
