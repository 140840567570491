import React from 'react'
import Cart from '../features/Cart/Cart';
const CartPage = () => {
  return (
    <div>
      <Cart></Cart>
    </div>
  )
}

export default CartPage;
