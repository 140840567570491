import React from "react";
import ForgotPassword from "../features/Auth/componenets/ForgotPassword";
const ForgotPasswordPage = () => {
  return (
    <>
      <ForgotPassword></ForgotPassword>
    </>
  );
};

export default ForgotPasswordPage;
