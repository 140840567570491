import React from 'react'
import AdminOrders from '../features/admin/components/AdminOrders'
const AdminOrdersPage = () => {
  return (
    <div>
          <AdminOrders></AdminOrders>
    </div>
  )
}

export default AdminOrdersPage
