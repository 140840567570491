import React from 'react'
import Signup from '../features/Auth/componenets/Signup'
const SignUp = () => {
  return (
    <div>
      <Signup></Signup>
    </div>
  )
}

export default SignUp
