import React from 'react'
import EmptyCart from '../features/Cart/EmptyCart'
const EmptyCartPage = () => {
  return (
    <div>
          <EmptyCart></EmptyCart>
    </div>
  )
}

export default EmptyCartPage
